import React, {useEffect, useMemo, useState} from "react";
import throttle from "lodash/throttle";
import {useDocumentTranslation} from "../documents/documentContext";
import {If} from "../app/If";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import {useTheme} from "@emotion/react";

export const KulturNavLicenseSearch = ({
                                           id,
                                           searchHandler,
                                           onChange,
                                           onClear,
                                           disabled,
                                           inputLabel,
                                           Icon,
                                           value,
                                           multiple = true,
                                           size = null,
                                           helperText = null,
                                       }) => {
    const theme = useTheme();
    const [inputValue] = useState("");
    const [options, setOptions] = useState([]);
    const [searching, setSearching] = useState(false);
    const t = useDocumentTranslation();

    const fetchOptions = useMemo(
        () =>
            throttle((request, callback) => {
                const params = request
                    .split(" ")
                    .map((word) => `${word}*`)
                    .join(" ");
                setOptions([]);
                setSearching(true);
                searchHandler(params, (data) => callback(data));
            }, 400),
        [searchHandler]
    );

    const populateOptions = (results) => {
        const newOptions = results || [];
        setOptions(newOptions);
        setSearching(false);
    };

    useEffect(() => {
        let active = true;
        fetchOptions(inputValue, (results) => {
            if (active) {
                populateOptions(results);
            }
        });

        return () => {
            active = false;
        };
    }, [fetchOptions, inputValue]);

    const changeHandler = (_event, val, reason) => {
        if ("selectOption" === reason || "removeOption" === reason) {
            onChange(val);
        } else if ("clear" === reason) {
            onClear();
        }
    };

    return (
        <Autocomplete
            id={id}
            key={id}
            freeSolo={true}
            handleHomeEndKeys={true}
            disabled={disabled}
            multiple={multiple}
            loading={Boolean(searching && inputValue)}
            loadingText={t("searchingPlaceholder", "Søker...")}
            getOptionLabel={(option) => option.title || ""}
            options={options}
            autoComplete={true}
            includeInputInList={true}
            filterSelectedOptions={true}
            isOptionEqualToValue={(option, val) =>
                option.uniqueId !== undefined && option.uniqueId === val.uniqueId
            }
            ChipProps={{color: "secondary"}}
            value={value}
            openOnFocus={true}
            onChange={changeHandler}
            onInputChange={(_event, _newInputValue) => {
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={inputLabel}
                    size={size}
                    helperText={helperText}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <Box component={"li"} {...props}>
                        <If boolean={option.create}>
                            <AddIcon
                                sx={{
                                    color: theme.palette.text.secondary,
                                    marginRight: theme.spacing(2),
                                }}
                            />
                            <Typography variant="body2" color="textSecondary">
                                {option.create ? t("createNew", "Opprett") : option.source}
                            </Typography>
                        </If>
                        <If boolean={!option.create}>
                            <Icon
                                sx={{
                                    color: theme.palette.text.secondary,
                                    marginRight: theme.spacing(2),
                                }}
                            />
                            {option.title}
                        </If>
                    </Box>
                );
            }}
        />
    );
};

import React from "react";
import {grey} from "@mui/material/colors";
import {createGenerateClassName, makeStyles, StylesProvider,} from "@mui/styles";
import {createTheme, ThemeProvider} from "@mui/material";
import Box from "@mui/material/Box";

const generateClassName = createGenerateClassName({
    seed: "ekulturDAMS",
});

export const Theme = ({children}) => {
    const appTheme = createAppTheme();
    const classes = useStyles();

    return (
        <ThemeProvider theme={appTheme}>
            <StylesProvider generateClassName={generateClassName}>
                <Box className={classes.root}>{children}</Box>
            </StylesProvider>
        </ThemeProvider>
    );
};

export const themeOptions = {
    palette: {
        primary: {
            main: "#673AB7",
            light: "#D1C4E9",
            dark: "#512DA8",
        },
        secondary: {
            main: "#009688",
        },
        background: {
            default: grey[100],
        },
    },
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: '2.5rem',
                },
            },
        },
    },
    props: {
        MuiTextField: {
            variant: "filled",
            color: "secondary",
        },
        MuiFormLabel: {
            color: "secondary",
        },
        MuiSelect: {
            variant: "filled",
            color: "secondary",
        },
    },
};

const useStyles = makeStyles(() => ({
    root: {
        zIndex: 1,
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
    },
}));

const createAppTheme = () => createTheme(themeOptions);

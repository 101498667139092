import {SET_SEARCHING, useFoldersDispatch, useFoldersTranslation,} from "../../folder/FoldersContext";
import React, {useEffect, useState} from "react";
import {Button, Dialog, DialogContent, useMediaQuery} from "@mui/material";
import {TOGGLE_ADD_TO_FOLDER, useResultActionDispatch, useResultActionState} from "../ResultActionContext";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import LoadingButton from "@mui/lab/LoadingButton";
import FolderIcon from "@mui/icons-material/Folder";
import {usePostFolderDocument} from "../../folder/usePostFolderDocument";
import {SET_SELECTED, UNSELECT_ALL, useSearchDispatch} from "../SearchContext";
import {ADD_MESSAGE, useSnackbarDispatch} from "../../snackbar/SnackbarContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useMyMuseums} from "../../museum/useMyMuseums";
import PropTypes from "prop-types";
import {FoldersTable} from "./FoldersTable";


/**
 * Renders the AddToFolderModal component.
 *
 * @param {Object} selectedDocuments - The selected documents to be added to a folder.
 * @return {JSX.Element} The AddToFolderModal component JSX.
 */
const AddToFolderModal = ({selectedDocuments}) => {

    const t = useFoldersTranslation();

    const myMuseums = useMyMuseums({});

    const {showAddToFolder} = useResultActionState();

    const dispatch = useResultActionDispatch();
    const foldersDispatch = useFoldersDispatch();
    const searchDispatch = useSearchDispatch();
    const snackbarDispatch = useSnackbarDispatch();

    const [selectedFolder, setSelectedFolder] = useState(null);
    const [ready, setReady] = useState(false);

    const [postFolderDocumentResponse, postFolderDocument, clearAll] = usePostFolderDocument(selectedFolder?.id);

    const smallScreen = useMediaQuery("(max-width: 768px)");


    /**
     * Shows the error snackbar if one or more documents were not added to the selected folder.
     */
    const showErrorSnackbar = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("addToFolderErrorTitle", "Feilet"),
                body: t(
                    "addToFolderErrorMessage",
                    "{{count}} dokumenter ble ikke lagt til i mappen {{folderName}}",
                    {
                        count: postFolderDocumentResponse.error,
                        folderName: selectedFolder?.title
                    }
                ),
                type: "error",
            },
        });
    };

    /**
     * Snackbar displayed if all documents were successfully added to the selected folder.
     */
    const showSuccessSnackbar = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("addToFolderSuccessTitle", "Lagret"),
                body: t(
                    "addToFolderSuccessMessage",
                    "{{count}} dokumenter ble lagt til i mappen {{folderName}}",
                    {
                        count: postFolderDocumentResponse.success,
                        folderName: selectedFolder?.title
                    }
                ),
                type: "success",
            },
        });
    }

    /**
     * Close the dialog
     */
    const onHide = () => {
        setSelectedFolder(null);
        setReady(false);
        dispatch({type: TOGGLE_ADD_TO_FOLDER});
    };

    /**
     * Add the selected documents to the selected folder.
     */
    const addToFolderHandler = () => {
        if (!selectedFolder) {
            return;
        }
        selectedDocuments.forEach(model => postFolderDocument({documentId: model.uniqueId}));
    };

    /**
     * Check if the "Add to folder" button should be disabled.
     *
     * The button is disabled if no folder has been selected.
     * @returns {boolean} True if the button should be disabled, false otherwise.
     */
    const disableAddToFolderButton = () => {
        return !selectedFolder || selectedFolder === null;
    };

    /**
     * Await initial search until museums have been resolved, and the dialog is actually opened.
     */
    useEffect(() => {
        if (myMuseums.length === 0 || ready || !showAddToFolder) {
            return;
        }

        setReady(true);
        foldersDispatch({
            type: SET_SEARCHING
        });
    }, [myMuseums, ready, foldersDispatch, showAddToFolder]);

    useDeepCompareEffect(() => {
        const {pending, error, success, failingIds} = postFolderDocumentResponse;

        if (pending > 0) {
            return;
        }

        // Documents are added to the folders, unselect selected documents in the search results.
        searchDispatch({type: UNSELECT_ALL});

        if (error > 0) {
            showErrorSnackbar();

            // Make the failing documents appear still selected in the search results.
            searchDispatch({
                type: SET_SELECTED,
                selected: [failingIds]
            });
            onHide();
        }

        if (success > 0) {
            clearAll();
            showSuccessSnackbar();
            onHide();
        }
    }, [postFolderDocumentResponse]);

    return (
        <Dialog
            open={showAddToFolder}
            onClose={onHide}
            PaperProps={{
                style: {
                    overflow: 'hidden',
                    minWidth: smallScreen ? '100vw' : '1024px',
                    maxWidth: smallScreen ? '100vw' : '1024px',
                    width: smallScreen ? '100vw' : '1024px',
                    height: '768px',
                    minHeight: '768px',
                    maxHeight: '768px'
                }
            }}>
            <DialogTitle>
                {t('addFoldersHeader', 'Legg til i mappe')}
            </DialogTitle>
            <DialogContent sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                flexGrow: 1

            }}>
                <FoldersTable t={t} setFolderCallback={setSelectedFolder}/>
            </DialogContent>
            <DialogActions>
                <Button color={"secondary"} onClick={onHide}>
                    {t('cancel', 'Avbryt')}
                </Button>
                <LoadingButton
                    loading={postFolderDocumentResponse.pending > 0}
                    variant={"contained"}
                    color={"primary"}
                    onClick={addToFolderHandler}
                    loadingPosition={"start"}
                    startIcon={<FolderIcon/>}
                    disabled={disableAddToFolderButton()}
                >
                    {t('addToFolderButton', 'Legg i mappe')}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

AddToFolderModal.propTypes = {
    "selectedDocuments": PropTypes.any.isRequired
};

export {AddToFolderModal};

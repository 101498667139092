import * as Sentry from "@sentry/react";
import {useEffect} from "react";
import {createRoutesFromChildren, useLocation, useNavigationType} from "react-router-dom";
import {matchRoutes} from "react-router";

const mode = window._env_.REACT_APP_X_QUOTE_MODE;
const version = window._env_.REACT_APP_VERSION;

console.log(`DAMS - ${version} (${mode})`);

Sentry.init({
    dsn: "https://8d0372db9ba34f90831274056d515551@o1289125.ingest.sentry.io/4504598320775168",
    enabled: ['production', 'beta', 'development', 'local'].includes(mode),
    release: version,
    environment: mode,
    tracesSampleRate: (mode === 'production') ? 0.2 : 1.0,
    replaySessionSampleRate: (mode === 'production') ? 0.2 : 1.0,
    replaysOnErrorSampleRate: 1.0,
    ignoreErrors: ['UnhandledRejection: Non-Error promise rejection captured with value: 401'],
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration({
            maskAllInputs: true,
            maskAllText: true,
            blockAllMedia: true,
            stickySession: true
        }),
        Sentry.browserProfilingIntegration()
    ],
});

import {getBrowserLocale} from "../utility";

/**
 * Fetches a JSON object from the KulturNav API core endpoint.
 *
 * @param {string} params The API endpoint to call.
 * @return {Promise} A Promise that resolves with the JSON object returned from
 * the API.
 */
export const kulturnavApiCoreFetch = (params) => {
    const locale = getBrowserLocale(true);
    const url = `https://kulturnav.org/api/core/${params}&displayValues=true&lang=${locale}`;
    return fetch(url, {
        headers: {
            'Accept-Language': locale
        }
    }).then(response => response.json());
}

/**
 * Fetches a JSON object from the KulturNav summary API endpoint.
 *
 * @param {string} path The API endpoint to call.
 * @return {Promise} A Promise that resolves with the JSON object returned from
 * the API.
 */
export const kulturnavFetch = (path) => {
    const kulturNavSummary = `https://kulturnav.org/api/summary`;
    return fetch(`${kulturNavSummary}${path}`, {
        headers: {
            "Accept-Language": "no",
        },
    }).then((response) => response.json());
};

/**
 * Fetches a JSON object from the API endpoint on data.arkeologi.org.
 *
 * @param {string} path The API endpoint to call.
 * @return {Promise} A Promise that resolves with the JSON object returned from
 * the API.
 */
export const dataArcheologyServerFetch = (path) => {
    const dataArcheologyApi = `https://data.arkeologi.org/api/`;
    return fetch(`${dataArcheologyApi}${path}`, {
        headers: {
            "Accept-Language": "no",
        },
    }).then((response) => response.json());
};

import React, {useCallback, useEffect, useState} from "react";
import {useSearchState, useSearchTranslation} from "../SearchContext";
import {AccordionFilter} from "../AccordionFilter";
import {FilterCheckbox} from "./FilterCheckbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export const FilterProjectState = ({callback}) => {
    const t = useSearchTranslation();
    const {fq, results, clearFilters} = useSearchState();
    const projectStateKeys = [
        "archeologyprojectref.active",
        "generalprojectref.active"
    ];

    const [selected, setSelected] = useState([]);

    const changeHandler = (value) => () => {
        modifySelectedFilter(value);
    };

    const resetValues = (selectedFilters) => {
        for (let i = 0, max = projectStateKeys.length; i < max; i++) {
            const ix = selectedFilters.findIndex(f => f.key === projectStateKeys[i]);
            selectedFilters.splice(ix, 1);
        }
    };

    const modifySelectedFilter = useCallback(
        (value, fromFq) => {
            let selectedFilters = selected;
            if (fromFq) {
                if (
                    !selectedFilters.find(
                        (s) => s.value === value && projectStateKeys.includes(s.key)
                    )
                ) {
                    selectedFilters.push({key: projectStateKeys[0], value: value});
                    selectedFilters.push({key: projectStateKeys[1], value: value});
                    setSelected(selectedFilters);
                }
            } else {
                if (
                    !selectedFilters.find(
                        (s) => projectStateKeys.includes(s.key)
                    )
                ) {
                    resetValues(selectedFilters);
                    selectedFilters.push({key: projectStateKeys[0], value: value});
                    selectedFilters.push({key: projectStateKeys[1], value: value});
                    setSelected(selectedFilters);
                } else {
                    resetValues(selectedFilters);
                    setSelected([])
                    callback(projectStateKeys, []);
                }
            }
            callback(projectStateKeys, selectedFilters);
        },
        [callback, selected]
    );

    useEffect(() => {
        if (clearFilters) {
            setSelected([]);
            callback(projectStateKeys, []);
        }
    }, [clearFilters]);

    useEffect(() => {
        if (fq) {
            // Add selected filters from existing query
            fq.filter((f) => f.key === projectStateKeys).forEach((f) => {
                modifySelectedFilter(f.value.replaceAll('"', ""), true);
            });
        }
    }, [fq]);

    const projectStates = results.facets.projectStates;

    const count = Object.keys(projectStates).length;
    if (!count) {
        return null;
    }

    const getLabel = (v) => {
        return !v
            ? t('filterProjectStateLabelDeactivated', 'Deaktivert')
            : t('filterProjectStateLabelActive', 'Aktivt');
    }

    return (
        <>
            <AccordionFilter
                title={`${t("filterProjectStateHeader", "Prosjektstatus")} (${count})`}
                count={count}
            >
                <List>

                    <ListItem
                        key={"projects-active"}
                        dense={true}
                        disablegutters={"true"}
                        onClick={changeHandler(true)}
                    >
                        <ListItemIcon>
                            <FilterCheckbox
                                selected={selected}
                                subjectsKey={projectStateKeys[0] || projectStateKeys[1]}
                                value={true}
                            />
                        </ListItemIcon>
                        <ListItemText id={`label-projects-active`}>
                            {getLabel(true)} ({projectStates[true].count})
                        </ListItemText>
                    </ListItem>

                    <ListItem
                        key={"projects-inactive"}
                        dense={false}
                        disablegutters={"true"}
                        onClick={changeHandler(false)}
                    >
                        <ListItemIcon>
                            <FilterCheckbox
                                selected={selected}
                                subjectsKey={projectStateKeys[0] || projectStateKeys[1]}
                                value={false}
                            />
                        </ListItemIcon>
                        <ListItemText id={`label-projects-inactive`}>
                            {getLabel(false)} ({projectStates[false].count})
                        </ListItemText>
                    </ListItem>

                </List>
            </AccordionFilter>
        </>
    );
};

import {useCallback, useEffect, useState} from "react";
import {damsFetch} from "../app/damsFetch";
import {kitFetch} from "@ekultur/fetch";
import {clientLog} from "../clientLog";
import {
    SET_DAMS_ID,
    SET_MUSEUM_COLLECTIONS,
    SET_MUSEUM_FEATURES,
    SET_USER_DATA,
    useAuthsDispatch,
    useAuthsState
} from "./authsContext";
import {getI18n} from "react-i18next";

/**
 * @description
 * Higher order component that fetches and sets user data and other related data.
 * If the user is not authenticated, it will not render the children.
 *
 * The component will fetch and set the following data:
 * - User data
 * - Museum collections
 * - Dams ID
 * - Museum features (if the user has access to the archeology module)
 *
 * @param {ReactNode} children - The children to render.
 *
 * @returns {ReactNode} - The children if the user is authenticated, otherwise an empty fragment.
 */
export const WithUserData = ({children}) => {

    const {userData, userIsAuthenticated} = useAuthsState();

    const [loading, setLoading] = useState(true);

    const authsDispatch = useAuthsDispatch();

    const authsDispatchCallback = useCallback(
        (action) => authsDispatch(action),
        [authsDispatch]
    );

    const setUserDamsId = useCallback(async () => {
        try {
            const data = await damsFetch('/current-user/my-dams-id/');
            authsDispatchCallback({
                type: SET_DAMS_ID,
                userDamsId: data['id']
            });
        } catch (error) {
            clientLog('error', error.toString(), 'withuserdata');
        }
    }, [authsDispatchCallback]);

    const setMuseumsWithArcheologyModuleEnabled = useCallback(async (collectionData) => {
        const data = await kitFetch('/museum-api/feature-toggles/DAMSArkeologi/');
        const museumsWithArcheologyFeature = data.map(d => d.museumId);
        const museums = collectionData
            .map(c => ({id: c.museumId}))
            .map(m => ({
                ...m,
                features: {
                    archeology: museumsWithArcheologyFeature.includes(m.id)
                }
            }));
        authsDispatch({type: SET_MUSEUM_FEATURES, museumFeatures: museums});
    }, [authsDispatch]);

    const authorizeUser = useCallback(async () => {
        try {
            return await kitFetch('/authz/authorize/userData');
        } catch (error) {
            clientLog('error', error.toString(), 'withuserdata');
        }
    }, []);

    const getMuseumCollections = async () => {
        try {
            return await damsFetch('/collections/my-museum-collections/');
        } catch (error) {
            clientLog('error', error.toString(), 'withuserdata');
        }
    };

    const updateCurrentUser = async (username) => {
        return await damsFetch('/current-user/', {
            method: 'POST',
            body: JSON.stringify({name: username})
        });
    };

    useEffect(() => {
        if (!userIsAuthenticated || Object.keys(userData).length > 0) {
            return;
        }
        authorizeUser().then(async (userData) => {
            authsDispatchCallback({
                type: SET_USER_DATA,
                userData: userData
            });

            const collectionData = await getMuseumCollections();

            if (!collectionData) {
                return;
            }

            authsDispatchCallback({
                type: SET_MUSEUM_COLLECTIONS,
                museumCollections: collectionData
            });

            await setMuseumsWithArcheologyModuleEnabled(collectionData);
            await setUserDamsId()

            const {name, locale = undefined} = userData;

            await updateCurrentUser(name);
            if (locale) {
                await getI18n().changeLanguage(locale);
            }

            setLoading(false);
        });
    }, [
        userIsAuthenticated,
        userData,
        userData.name,
        authorizeUser,
        setUserDamsId,
        setMuseumsWithArcheologyModuleEnabled,
        authsDispatchCallback
    ]);


    if (!loading && Object.keys(userData).length > 0) {
        return <>{children}</>;
    } else {
        return <></>;
    }
};
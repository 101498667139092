import React from "react";
import Fade from "@mui/material/Fade";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * A component that renders a progress bar with a fade in animation.
 *
 * @returns A JSX element containing a progress bar.
 */
export const ProgressIndicator = () => {
    return (
        <div>
            <Fade
                in={true}
                style={{
                    transitionDelay: "400ms",
                }}
                unmountOnExit={true}
            >
                <LinearProgress/>
            </Fade>
        </div>
    );
};

import {Dialog, DialogContent, Stack} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import useNetworkStatus from "./useNetworkStatus";
import {useEffect, useState} from "react";
import {WarningAmber} from "@mui/icons-material";
import {useAppTranslation} from "./AppContext";
import * as Sentry from "@sentry/react";

/**
 * Shows a dialog when the network connection is lost.
 *
 * @returns {JSX.Element} A dialog component.
 */
export const AppOffline = () => {
    const t = useAppTranslation();

    const {isOnline} = useNetworkStatus();

    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        if (!isOnline) {
            Sentry.captureMessage('DAMS offline');
        }
        setShowDialog(!isOnline);
    }, [isOnline]);

    return <Dialog open={showDialog}>
        <DialogTitle>
            <Stack direction={"row"}>
                <WarningAmber color={"red"} sx={{marginRight: '8px'}}/>
                {t('network', 'Nettverk')}
            </Stack>
        </DialogTitle>
        <DialogContent>
            {t('networkOffline', 'Nettverket er ikke tilgjengelig.')}
        </DialogContent>
    </Dialog>;
};
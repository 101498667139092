import {useEffect, useState} from "react";

/**
 * A hook to check the current network status.
 *
 * Listens to the 'online' and 'offline' events and returns
 * an object with the property `isOnline` which is a boolean
 * indicating whether the browser is online or not.
 *
 * @returns {{isOnline: boolean}} An object with a boolean property.
 *
 * @example
 * const {isOnline} = useNetworkStatus();
 *
 * if (isOnline) {
 *     // Do something when the browser is online.
 * } else {
 *     // Do something when the browser is offline.
 * }
 */
const useNetworkStatus = () => {
    const [isOnline, setOnline] = useState(true);

    const updateNetworkStatus = () => {
        setOnline(navigator.onLine);
    };

    useEffect(() => {
        window.addEventListener("online", updateNetworkStatus);
        window.addEventListener("offline", updateNetworkStatus);

        return () => {
            window.removeEventListener("online", updateNetworkStatus);
            window.removeEventListener("offline", updateNetworkStatus);
        };
    }, [navigator.onLine]);

    return {isOnline};
};

export default useNetworkStatus;
import {useAuthsState} from "./authsContext";

/**
 * Hook returning true if user has admin-role in specified app, defaulting to the app defined in the
 * REACT_APP_ID environment variable.
 *
 * @param {string} appId id of the app to check
 * @returns {boolean} true if user is an admin of the app, false otherwise
 */
export const useIsAppAdmin = (appId = window._env_.REACT_APP_ID) => {
    const {userData} = useAuthsState();

    return (
        userData['appAccess'] &&
        Boolean(
            userData['appAccess'].apps.find(
                (app) =>
                    appId === app.id && app.role.name === "Admin"
            )
        )
    );
};

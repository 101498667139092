import './instrument';
import React, {useMemo} from "react";
import {Theme} from "./Theme";
import {BrowserRouter as Router} from "react-router-dom";
import {Menu} from "../menu/Menu";
import {FloatingActionButton} from "../menu/FloatingActionButton";
import {MenuTranslationProvider} from "../menu/menuTranslationContext";
import CssBaseline from "@mui/material/CssBaseline";
import {SnackbarProvider} from "../snackbar/SnackbarContext";

import {AppProvider} from "./AppContext";
import {WhenHeaderIsReady} from "../header/WhenHeaderIsReady";
import {AppHeader} from "../header/AppHeader";
import {HeaderProvider} from "../header/headerContext";
import {AppContent} from "./AppContent";
import {RoutesAnonymous} from "./routes/RoutesAnonymous";
import {RoutesAuthenticated} from "./routes/RoutesAuthenticated";
import {DmsStatus} from "../dmsstatus/DmsStatus";
import {AppSnackbar} from "../snackbar/AppSnackbar";
import {ProjectProvider} from "../project/projectContext";
import {UpdateAppSnackbar} from "./UpdateAppSnackbar";
import {FileUploadProvider} from "../damsfileupload/fileUploadContext";
import {DownloadWorkerProvider} from "../workerstatus/workerStatusContext";
import {DMSStatusWorkerListener} from "../workerstatus/dmsStatusWorkerListener";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {AppOffline} from "./AppOffline";
import {WithUserData} from "../auths/WithUserData";

export const App = () => {
    const queryClient = new QueryClient();

    useMemo(() => {
        // Instantiates WebWorker, used when downloading files.
        window._downloadWorkerInstance = new Worker(new URL('../webworkers/downloadWorker.js', import.meta.url));

        // Instantiates WebWorker, used when fetching DMS-status.
        window._dmsStatusWorkerInstance = new Worker(new URL('../webworkers/dmsStatusWorker.js', import.meta.url));
    }, []);

    if (!window._env_ || !window._env_.REACT_APP_API_GATEWAY) {
        return null;
    }

    return (
        <Theme>
            <CssBaseline/>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <AppProvider>
                        <AppOffline/>
                        <HeaderProvider>
                            <AppHeader/>
                            <WhenHeaderIsReady>
                                <DownloadWorkerProvider>
                                    <AppContent>
                                        <RoutesAnonymous/>
                                        {/* WithUserData must be used here, as the menu is operating in the context of the current user */}
                                        <WithUserData>
                                            <MenuTranslationProvider>
                                                <UpdateAppSnackbar/>
                                                <FileUploadProvider>
                                                    <ProjectProvider>
                                                        <Menu/>
                                                        <SnackbarProvider>
                                                            <DMSStatusWorkerListener/>
                                                            <DmsStatus/>
                                                            <RoutesAuthenticated/>
                                                            <AppSnackbar/>
                                                            <FloatingActionButton/>
                                                        </SnackbarProvider>
                                                    </ProjectProvider>
                                                </FileUploadProvider>
                                            </MenuTranslationProvider>
                                        </WithUserData>
                                    </AppContent>
                                </DownloadWorkerProvider>
                            </WhenHeaderIsReady>
                        </HeaderProvider>
                    </AppProvider>
                </Router>
            </QueryClientProvider>
        </Theme>
    );
};

import {DOWNLOADS_START} from "../workerstatus/workerStatusContext";

/**
 * Method used to kick off the download web worker.
 * @param workerConfig  object  The web worker config. object.
 * @param jobName   str The name of the job, will be used in the GUI.
 * @param documents Array   An array holding the documents/models/files to be downloaded.
 * @param downloadWorkerDispatch method Used to dispatch messages from the web worker.
 * @param imageProps object    Custom image size object, if downloading one or more image files.
 * @param audioFormat obj  The audio format, if downloading one or more audio files.
 * @param videoFormat obj   The video format, if downloading one or more video files.
 */
export const kickOffDownloadWorker = ({
                                          workerConfig,
                                          jobName,
                                          documents,
                                          downloadWorkerDispatch,
                                          imageProps = null,
                                          audioFormat = null,
                                          videoFormat = null
                                      }) => {
    // Create the download job object.
    const job = {
        jobId: crypto.randomUUID(),
        name: jobName,
        active: true,
        complete: false,
        files: documents,
        imageProps: imageProps,
        audioFormat: audioFormat,
        videoFormat: videoFormat,
        stages: []
    };

    // Signal that we're initiating a new job.
    downloadWorkerDispatch({
        type: DOWNLOADS_START,
        job: job
    });

    // Kick off web worker.
    window._downloadWorkerInstance.postMessage(JSON.stringify({
        config: workerConfig,
        action: 'downloadFiles',
        channel: 'worker',
        job: job
    }));
};
import React, {useEffect, useRef} from "react";
import {styled, useTheme} from "@mui/material/styles";
import {ResultTableHead} from "./ResultTableHead";
import {
    SELECT,
    SET_SEARCHING,
    SET_SELECTED,
    SET_SORT,
    UNSELECT,
    useSearchDispatch,
    useSearchState,
} from "./SearchContext";
import {getTableColumns} from "./tableColumns";
import {ResultTableColumnSelector} from "./ResultTableColumnSelector";
import {
    SHOW,
    useSearchResultDetailsDispatch,
    useSearchResultDetailsTranslation,
} from "../search-result-details/SearchResultDetailsContext";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import {SearchSettings} from "./SearchSettings";
import {FolderSearchSettings} from "./FolderSearchSettings";
import {useLocation, useNavigate} from "react-router-dom";
import {SET_AVAILABLE_COLUMNS, useResultTableDispatch, useResultTableState} from "./ResultTableContext";
import {decamelize} from "../app/decamelize";
import {useMediaQuery} from '@mui/material';
import {getMuseumNameFromCollectionId} from "../utility";
import {useMyMuseums} from "../museum/useMyMuseums";
import {useAuthsState} from "../auths/authsContext";

const PREFIX = "ResultTable";

const classes = {
    table: `${PREFIX}-table`,
};

const Root = styled("div")(() => ({
    [`& .${classes.table}`]: {
        // minWidth: 750, // no longer relevant?

    },
}));

export const ResultTable = ({folderId, dataRows}) => {
    const myMuseums = useMyMuseums({});
    const {museumCollections} = useAuthsState();

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const folderSearchSettings = FolderSearchSettings('dams.folderSearchResults.config');
    const searchSettings = SearchSettings('dams.searchResults.config');

    const {sort, selected} = useSearchState();
    const searchDispatch = useSearchDispatch();
    const [orderBy, order] = sort.split(" ");
    const dispatch = useSearchResultDetailsDispatch();
    const t = useSearchResultDetailsTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const tableColumns = getTableColumns();

    const isProjectList = location.pathname === "/all-projects/";   // Listing projects

    const resultTableDispatch = useResultTableDispatch();
    const {columnsToRender} = useResultTableState();

    const contextKey = 'search';
    const filteredTableColumns = useRef();

    /**
     * Hook used to add the name of the museum, when viewing projects, as this information is not stored
     * together with the actual project data.
     */
    const appendMuseumName = dataRows => {
        if (!isProjectList || !myMuseums || !museumCollections) {
            return dataRows;
        }
        return dataRows.map(r => ({
            ...r,
            museumName: getMuseumNameFromCollectionId({
                museums: myMuseums,
                museumCollections: museumCollections,
                collectionId: r.collectionId
            })
        }));
    };

    useEffect(() => {
        if (!tableColumns) {
            return;
        }

        // Disable sorting on the "virtual column" "museumName":
        const colMuseumName = tableColumns.find(tc => tc.id === 'museumName');
        if (colMuseumName) {
            colMuseumName.disableSorting = true;
        }

        // If a column config is stored, use it!
        const savedCols = localStorage.getItem('dams.' + contextKey + '.columns');

        filteredTableColumns.current = tableColumns;

        if (savedCols) {
            filteredTableColumns.current = tableColumns.map(tc => {
                return {
                    ...tc,
                    checked: savedCols.includes(tc.id),
                    defaultHidden: true
                }
            });
        }
    }, [tableColumns]);


    /**
     * Hook used to get, and set the default available columns.
     */
    useEffect(() => {

        function checkActive(cols) {
            return cols.map(c => {
                let render = false;
                if (!c.checked && c.defaultHidden === false) {
                    render = true;
                }
                if (c.checked) {
                    render = c.checked;
                }
                return {...c, checked: render};
            });
        }

        let columns;

        if (isProjectList) {
            // Columns that should not be available under the list of projects,
            // are marked with the property "projectList: false" in the file tableColumns.js
            columns = filteredTableColumns.current.filter(tc => {
                return tc.projectList === isProjectList || typeof (tc.projectList) === 'undefined';
            });
            columns = checkActive(columns);
            resultTableDispatch({
                type: SET_AVAILABLE_COLUMNS,
                availableColumns: columns,
                contextKey: contextKey
            })
        } else {
            // Columns marked with projectList: true, should only appear in the project list.
            columns = checkActive(filteredTableColumns.current.filter(tc => {
                return !tc.projectList || typeof (tc.projectList) === 'undefined'
            }));
            resultTableDispatch({
                type: SET_AVAILABLE_COLUMNS,
                availableColumns: columns,
                contextKey: contextKey
            });
        }
    }, [
        isProjectList,
        resultTableDispatch,
        filteredTableColumns
    ]);


    const setSelected = (selection) => {
        searchDispatch({
            type: SET_SELECTED,
            selected: selection,
        });
    };

    const handleRequestSort = (_event, property) => {
        const isAsc = orderBy === decamelize(property) && order === "asc";
        const sortOrder = isAsc ? "desc" : "asc";
        searchDispatch({
            type: SET_SORT,
            sort: `${property} ${sortOrder}`,
        });
        !folderId
            ? searchSettings.setSortColumnAndOrder(property, sortOrder)
            : folderSearchSettings.setSortColumnAndOrder(property, sortOrder);
        searchDispatch({type: SET_SEARCHING});
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelected(dataRows);
            return;
        }
        setSelected([]);
    };

    const handleCheck = (isItemSelected, model) => {
        if (isItemSelected) {
            searchDispatch({
                type: UNSELECT,
                model: model,
            });
        } else {
            searchDispatch({
                type: SELECT,
                model: model,
            });
        }
    };

    const isSelected = (uniqueId) =>
        Boolean(selected.find((model) => uniqueId === model.uniqueId));

    const handleRowClick = (model) => {
        if (
            model.documentType.toLowerCase() === "folder" &&
            model.status === "project"
        ) {
            // Projects are special folders:
            // documentType: folder
            // status: project
            navigate("/project/" + model.id);
        } else {
            // Open the selected document in object view:
            dispatch({
                type: SHOW,
                model: model,
            });
        }
    };

    const getTableBody = () => {
        if (isProjectList) {
            dataRows = appendMuseumName(dataRows);
        }
        return <>
            {dataRows.map((row) => {
                const isItemSelected = isSelected(row.uniqueId);
                return (
                    <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.uniqueId}
                        selected={isItemSelected}
                        sx={{cursor: "pointer"}}
                    >
                        <TableCell
                            padding={"checkbox"}
                            onClick={() => handleCheck(isItemSelected, row)}
                        >
                            <Checkbox checked={isItemSelected}/>
                        </TableCell>
                        {columnsToRender.map((cell) => {
                            return (
                                <TableCell
                                    key={cell.id}
                                    {...cell.cellProps}
                                    align={cell.align ? cell.align : "left"}
                                    onClick={() => handleRowClick(row)}
                                >
                                    {cell.getValue(row, t)}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                );
            })}
        </>;
    };

    return (
        <Root sx={{overflow: 'hidden', display: 'flex', flexDirection: 'column', flexGrow: 1,}} id="ResultsTable-root">
            <Toolbar variant="dense" sx={{
                borderBottom: '1px solid #E0E0E0',
                "&.MuiToolbar-root": {
                    paddingLeft: 1,
                },
            }}>
                <ResultTableColumnSelector t={t}/>
            </Toolbar>
            <TableContainer sx={{flexGrow: 1}}>
                <Table
                    stickyHeader
                    className={classes.table}
                    aria-labelledby="search-results-title"
                    size={isSmallScreen ? "small" : "medium"}
                    aria-label="search-results-table"
                >
                    <ResultTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={dataRows.length}
                        t={t}
                    />
                    <TableBody>
                        {getTableBody()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Root>
    );
};

import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useMenuTranslation} from "../menu/menuTranslationContext";

/**
 * Shows a snackbar when a new version of the app is available.
 *
 * It listens to the "serviceWorkerUpdate" event and shows a snackbar with a button
 * to reload the page when a new version is available.
 *
 * @returns {JSX.Element} The snackbar component
 */
export const UpdateAppSnackbar = () => {

    const [showUpdateNotification, setShowUpdateNotification] = useState(false);

    const t = useMenuTranslation();

    const handleUpdateClick = () => {
        setShowUpdateNotification(false);
        window.location.reload();
    };

    const handleNewServiceWorker = () => {
        setShowUpdateNotification(true);
    };

    useEffect(() => {
        document.addEventListener('serviceWorkerUpdate', handleNewServiceWorker);
        return () => {
            document.removeEventListener('serviceWorkerUpdate', handleNewServiceWorker);
        };
    }, []);


    return <Snackbar open={showUpdateNotification}
                     severity={"info"}
                     autoHideDuration={10000}
                     message={t('newVersionAvailableTitle', 'Ny versjon tilgjengelig')}
                     action={<Button variant={"contained"}
                                     size={"small"}
                                     onClick={() => handleUpdateClick()}>{t('btnUpdate', 'Oppdater')}</Button>}/>;
};
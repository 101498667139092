import * as Sentry from "@sentry/react";

/**
 * Returns Sentry trace headers for use in an outgoing request.
 *
 * @returns an object with two properties, `baggage` and `trace`, each of which is a string value for the corresponding header.
 * The `baggage` property is defined as the `sentry-baggage` header, which contains user-level context for the request.
 * The `trace` property is defined as the `sentry-trace` header, which contains the trace ID and parent span ID.
 */
export const getSentryTraceHeaders = () => {
    const activeSpan = Sentry.getActiveSpan();
    const rootSpan = activeSpan ? Sentry.getRootSpan(activeSpan) : undefined;

    const sentryTraceHeader = rootSpan ? Sentry.spanToTraceHeader(rootSpan) : undefined;
    const sentryBaggageHeader = rootSpan ? Sentry.spanToBaggageHeader(rootSpan) : undefined;

    return {
        baggage: sentryBaggageHeader,
        trace: sentryTraceHeader
    };
};
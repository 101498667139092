import "react-app-polyfill/stable";
import React from "react";
import "./index.css";
import "./app/i18n";
import {App} from "./app/App";

import {createRoot} from "react-dom/client";

import * as serviceWorker from './serviceWorker';

const onSWUpdate = (registration) => {
    const event = new CustomEvent('serviceWorkerUpdate');
    document.dispatchEvent(event);
    serviceWorker = registration.waiting;
};

const container = document.getElementById("root");
const root = createRoot(container);

root.render(<App/>);

// Register the Service Worker.
serviceWorker.register({
    onUpdate: onSWUpdate
});
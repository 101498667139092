/**
 * Formats a given date string according to the given locale.
 *
 * @param {boolean} norwegianUser
 * @param {string} loadedDate
 * @returns {string}
 */
export const formatDateStr = (norwegianUser, loadedDate) => {
    if (loadedDate) {
        try {
            const d = new Date(loadedDate);
            const month = parseInt(d.getMonth() + 1)
                .toString()
                .padStart(2, "0");
            const dt = d.getDate().toString().padStart(2, "0");
            if (norwegianUser) {
                return dt + "." + month + "." + d.getFullYear();
            } else {
                return d.getFullYear() + "-" + month + "-" + dt;
            }
        } catch {
            const d = loadedDate.match(/^(\d{4}-\d{2}-\d{2})/gi)[0].split("-");
            if (norwegianUser) {
                return d[2] + "." + d[1] + "." + d[0];
            } else {
                return d[0] + "-" + d[1] + "-" + d[2];
            }
        }
    } else {
        return "";
    }
};
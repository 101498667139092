import {useDocumentDispatch} from "../../documents/documentContext";
import {useFormikContext} from "formik";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import {deleteHandler} from "../deletehandlers";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";

/**
 * Button used when deleting a license or a copyright clause.
 * @param fileUploadMode    boolean
 * @param objectType    string  license | clause
 * @param object    object  The object to be deleted
 * @param ix    in  The field's index
 * @returns {JSX.Element|string}
 * @constructor
 */
const BtnDelete = ({fileUploadMode, objectType, object, ix}) => {
    const docDispatch = useDocumentDispatch();
    const {setFieldValue, values: formikValues} = useFormikContext();
    const {values} = useFormikContext();    // Holds all form values

    /**
     * Method used to delete a license.
     * It will update the Formik 'values' object by removing the license at the given index (ix).
     * It will also remove the associated "license-keys" from the Formik values object.
     */
    const deleteLicense = () => {
        const updatedLicenses = [...values['licenses']];
        updatedLicenses.splice(ix, 1);

        // Update the "licenses" field in the Formik values object.
        setFieldValue('licenses', updatedLicenses).then();

        // Remove associated "license-keys" as well from the Formik values object.
        setFieldValue(`license${ix}`, undefined).then();
        setFieldValue(`licensesAssociated${ix}`, undefined).then();
    };

    if (fileUploadMode) {
        return '';
    }

    return (<>
            <Divider orientation={"vertical"} flexItem={true}
                     sx={{width: '3px', marginLeft: 1, marginRight: 1}}/>
            <IconButton
                key={`btnDelete${ix}`}
                size={"large"}
                variant={"contained"}
                onClick={() => objectType === 'license' ? deleteLicense() : deleteHandler(docDispatch, formikValues, setFieldValue, objectType, ix, object, values)}
            >
                <DeleteIcon/>
            </IconButton>
        </>
    );
};

BtnDelete.propTypes = {
    fileUploadMode: PropTypes.bool,
    objectType: PropTypes.string,
    object: PropTypes.object,
    ix: PropTypes.number
};

export {BtnDelete};